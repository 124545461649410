window.ProductsAppConfig = {
  APP_NAME: "#{appName}#",
  API_URL: "#{apiUrl}#",
  ENVIRONMENT: "#{environment}#",
  PRODUCTS_API_URL: "#{productsApiUrl}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
};
